import moment from 'moment';
import React, { useEffect } from "react";
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useDispatch, useSelector } from "react-redux";
import { api, getApiRequestConfig } from "../../api";
import { AdminContext, AdminContextType } from "../../context/AdminProvider";
import { useT } from "../../i18n";
import { fetchShopAppointmentBookings } from '../../redux/actions/appointmentBookings.actions';
import { StoreState } from "../../redux/reducers";
import { Appointment } from '../../redux/reducers/appointment.reducer';
import { AppointmentBooking } from "../../redux/reducers/appointmentBookings.reducer";
import { AuthAdmin } from "../../redux/reducers/authAdmin.reducer";
import { BookingReport } from "../../redux/reducers/bookingReport.reducer";
import { Event as EventType } from '../../redux/reducers/event.reducer';
import { Food as FoodType } from "../../redux/reducers/food.reducer";
import { AppDispatch } from '../../redux/store';
import style from '../../styles/pages/super-admin.module.scss';
import { numberFormat, to24Hour } from "../../utils";
import EventDonutChart from "../charts/EventDonutChart";
import ModalWithHeader from '../modals/ModalWithHeader';
import ShoppingBagHand from "../svgs/ShoppingBagHand";
import { useToast } from '../ToastProvider';
import Button from '../utils/Button';
import Select from "../utils/Select";

const AdminHomeCard = () => {
    const t = useT()
    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>();

    const localizer = momentLocalizer(moment)
    const adminContext = React.useContext<AdminContextType | null>(AdminContext)
    const [bookingReports, setBookingReports] = React.useState<BookingReport[]>([]);
    const [appointmentBookings, setAppointmentBookings] = React.useState<AppointmentBooking[]>([]);
    const events = useSelector<StoreState, EventType[]>(state => state.events)
    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)
    const appointments = useSelector<StoreState, Appointment[]>(state => state.appointment)
    const foods = useSelector<StoreState, FoodType[]>(state => state.foods)
    const [selectedEvent, setSelectedEvent] = React.useState("")
    const [showEventModal, setShowEventModal] = React.useState<boolean>(false)
    const [slotTime, setSlotTime] = React.useState<string>('')
    const [slotTimeError, setSlotTimeError] = React.useState<string>('')
    const [editAppointment, setEditAppointment] = React.useState<Appointment>()
    const [reservationId, setReservationId] = React.useState<string>('')
    console.log(appointmentBookings);

    const slotTimeChangeHandler = (e: { target: { name: string; value: string } }, index?: number) => {
        setSlotTime(e.target.value)
        setSlotTimeError('')
    }

    useEffect(() => {
        const token = authAdmin?.['auth-token']
        const config = getApiRequestConfig(token)
        if (selectedEvent)
            api.get(`shop/booking-reports/${selectedEvent}`, config).then(response => {
                if (response.status === 200) {
                    setBookingReports(response.data.data);
                } else {
                    throw { response }
                }
            }).catch((error) => {
            })
    }, [selectedEvent])

    useEffect(() => {
        const token = authAdmin?.['auth-token']
        const config = getApiRequestConfig(token)
        api.get(`shop/appointment/bookings`, config).then(response => {
            if (response.status === 200) {
                setAppointmentBookings(response.data.data);
            } else {
                throw { response }
            }
        }).catch((error) => {
        })
    }, [])

    const donutChartData = React.useMemo(() => {
        const dountCharts = bookingReports.map((orderReport: any) => {
            let obj = {
                name: orderReport._id.size,
                count: orderReport.data?.map((x: any) => x.count).reduce((a: any, b: any) => a + b, 0),
                color: '#' + Math.random().toString(10).slice(-6)
            }
            return obj
        })
        return dountCharts;
    }, [bookingReports])

    const totalReservations = React.useMemo(() => {
        return donutChartData.map((x: any) => x.count).reduce((a: any, b: any) => a + b, 0)
    }, [donutChartData])



    const appointmentLists = appointmentBookings.map((appointment) => {
        const timeSplit = appointment.slotTime?.split('-');
        return {
            id: appointment.id,
            appointmentId: appointment.appointmentId.id,
            start: new Date(appointment.appointmentDate + 'T' + to24Hour(timeSplit?.[0])),
            end: new Date(appointment.appointmentDate + 'T' + to24Hour(timeSplit?.[1])),
            title: appointment.appointmentId.names[0].name,
            // slots: appointment.slotTime
        }
    })
    console.log(appointments);


    const handleEventClick = (reservation: any) => {
        console.log(reservation);

        let app = appointments.find(f => f.id?.toString() === reservation.appointmentId.toString())
        setShowEventModal(true)
        setEditAppointment(app)
        setReservationId(reservation.id)
    }
    console.log(editAppointment);

    const updateSlotTimingHandler = () => {
        const token = authAdmin?.['auth-token']
        const config = getApiRequestConfig(token)
        if (slotTime && slotTime !== '') {
            api.put<Response>(`reservation/${reservationId}`, { slotTime: slotTime }, config)
                .then(response => {
                    console.log(response);
                    if (response.status === 200) {
                        toast('Slot Time updated')
                        onModalClose()
                        dispatch(fetchShopAppointmentBookings())
                    }

                }).catch(error => {
                    toast(error.response.data.message)
                })
        } else {
            setSlotTimeError('Slot time required')
        }
    }

    const onModalClose = () => {
        setShowEventModal(false)
        setReservationId('')
        setEditAppointment(undefined)
    }

    return (
        <>
            {showEventModal && <ModalWithHeader onClose={onModalClose}>
                <div className="row">
                    <div className='col-4 mb-3'>
                        <Select
                            containerClass='mb-3'
                            label={t("Schedule Time")}
                            value={slotTime}
                            placeholder={t('Select Time')}
                            errorText={slotTimeError}
                            options={editAppointment?.slots.map((slot) => { return { text: slot.fromTime.time + ' ' + slot.fromTime.timePeriod + '-' + slot.toTime.time + ' ' + slot.toTime.timePeriod, value: slot.fromTime.time + ' ' + slot.fromTime.timePeriod + '-' + slot.toTime.time + ' ' + slot.toTime.timePeriod } })}
                            onChange={slotTimeChangeHandler}
                        />
                    </div>
                    <Button onClick={updateSlotTimingHandler}>Update</Button>
                </div>
            </ModalWithHeader>}
            <div className="row">
                {authAdmin?.category === "Product" && <div className={style.headerTitle}>{t("Stocks Reports")}</div>}
                {authAdmin?.category === "Product" && foods.filter((f: FoodType) => (f.stock && f.stock <= 10 || f.stock === null)).map((f: FoodType) => (
                    <div className={`col-md-5 col-lg-4 col-xl-4`}>
                        <div className={`${style.cardSection} mb-3`} onClick={() => f.id && adminContext?.onEditFood?.(f.id)}>
                            <div className={style.homeContainer} >
                                <div className={style.countLabel}>
                                    <h6>{f.names[0].name}</h6>
                                    <ShoppingBagHand stroke='#404040' strokeWidth={1} />
                                </div>
                                <div className={`${f.stock != null && f.stock > 5 ? style.countg : style.countl}`}>
                                    {numberFormat(f.stock)}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {authAdmin?.category === "Booking" &&
                    <React.Fragment>
                        <div className={style.headerTitle}>{t("Calender view")}</div>
                        <div className={style.calenderCard}>
                            <Calendar
                                localizer={localizer}
                                defaultView={Views.WEEK}
                                events={appointmentLists}
                                startAccessor="start"
                                endAccessor="end"
                                style={{ height: 500, width: 1000 }}
                                onSelectEvent={(reservation) => handleEventClick(reservation)}
                            />
                        </div>
                    </React.Fragment>
                }
                {authAdmin?.category === "Event" &&
                    <>
                        <div className="row">
                            <div className="col-lg-4 col-sm-12">
                                <Select
                                    containerClass='mb-3'
                                    label={t("Event")}
                                    value={selectedEvent}
                                    placeholder={t('Select Event')}
                                    options={events.map((event) => { return { text: event.names[0].name, value: event.id } })}
                                    onChange={(e) => setSelectedEvent(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={`col-md-5 col-lg-4 col-xl-4`}>
                            <div className={`${style.cardSection} mb-3`}>
                                <div className={style.homeContainer} >
                                    <div className={style.countLabel}>
                                        <h6>{t("TOTAL RESERVATIONS")}</h6>
                                        <ShoppingBagHand stroke='#404040' strokeWidth={1} />
                                    </div>
                                    <div className={style.count}>{totalReservations}</div>
                                </div>
                            </div>
                        </div>
                        <div className={`col-md-5 col-lg-4 col-xl-4`}>
                            <div className={`${style.cardSection} mb-3`}>
                                <EventDonutChart data={donutChartData} />
                            </div>
                        </div>
                    </>
                }
            </div></>
    )
}

export default AdminHomeCard;